const appConfig = {
    // production
    // apiPrefix: 'https://api.rebatus.com',

    //staging
    apiPrefix: 'https://staging-api.rebatus.com',

    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
};

export default appConfig;
